function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
import { getFormData } from './recommendation.utils';
const SKIP_DEBUG = true;
export class RecommendationComponent extends lm.Composant {
  /**
   * The CSS selector to match the html sections that will be initialized by this component.
   */
  static get selector() {
    throw new Error('You have to implement the method get selector');
  }

  /**
   * A pattern that matches fragment IDs from the recommendation, used for filtering fragmentLoaded events.
   */

  /**
   * Constructor of the RecommendationComponent class.
   * The child class should call super(section) in its constructor.
   * The constructor should not do any side effect action.
   * @param htmlSection
   */
  constructor(htmlSection) {
    super(htmlSection.id.replace('component-', ''));
    this.section = htmlSection;
  }

  /**
   * Safely initialize the component. It checks if the section has already been initialized before calling the init method.
   * It is idempotent. It can be called multiple times without side effects.
   * After initialization, the section is marked as initialized with the data-js-initialized attribute.
   * @returns {boolean} true if the was not initialized yet and has been initialized, false otherwise.
   */
  safeInit() {
    if (this.constructor.sectionIsInitialized(this.section)) {
      SKIP_DEBUG || console.debug('Section', this.section.id, 'already initialized');
      return false;
    }
    SKIP_DEBUG || console.debug('Initializing section', this.section.id);
    this.constructor.setSectionInitialized(this.section);
    this.init();
    return true;
  }

  /**
   * Initialize the component by doing some side effect actions like adding event listeners, fetching data, updating DOM, etc.
   * Children class that override this method should call super.init() at the beginning of the method.
   */
  init() {
    setTimeout(() => {
      this.setDataMerchArbo();
    }, 2000);
  }

  /**
   * Call this static method once per page load, usually after dom ready event.
   * It initializes all html sections matching the selector and listen the lazy-loading event bus to
   * initialize the sections that might be lazy-loaded.
   */
  static setupOnceDomIsReady() {
    SKIP_DEBUG || console.debug('setup', this.selector);
    this.getSectionsNotAlreadyInitialized().forEach(section => {
      new this(section).safeInit();
    });
    if (this.lazyLoadable) {
      this.addLazyLoadingListener();
    }
  }

  /**
   * Return true if the section has already been initialized.
   * @param htmlSection
   * @returns {boolean}
   */
  static sectionIsInitialized(htmlSection) {
    return htmlSection.getAttribute('data-js-initialized') === 'true';
  }

  /**
   * Mark the section as initialized.
   * @param htmlSection
   */
  static setSectionInitialized(htmlSection) {
    htmlSection.setAttribute('data-js-initialized', 'true');
  }

  /**
   * Return the sections that have not been initialized yet.
   */
  static getSectionsNotAlreadyInitialized() {
    return Array.from(document.querySelectorAll(this.selector)).filter(section => !this.sectionIsInitialized(section));
  }

  /**
   * Listen the lazy-loading event bus to be ready to initialize the sections that are lazy-loaded.
   */
  static addLazyLoadingListener() {
    const lazyLoadingEventBus = window['lazy-loading-bus'];
    if (!lazyLoadingEventBus) {
      console.warn('Lazy loading event bus not found');
      return;
    }
    if (this.isLazyLoadingListenerAdded()) {
      console.warn('Lazy loading event bus already listened');
      return;
    }
    this.lazyLoadingListener = event => this.handleFragmentLoadedEvent(event);
    lazyLoadingEventBus.on('fragmentLoaded', this.lazyLoadingListener);
  }

  /**
   * Remove the listener of the lazy-loading event bus.
   */
  static removeLazyLoadingListener() {
    const lazyLoadingEventBus = window['lazy-loading-bus'];
    if (!lazyLoadingEventBus) {
      console.warn('Lazy loading event bus not found');
      return;
    }
    if (!this.isLazyLoadingListenerAdded()) {
      console.warn('Lazy loading event bus not listened');
      return;
    }
    lazyLoadingEventBus.off('fragmentLoaded', this.lazyLoadingListener);
    this.lazyLoadingListener = undefined;
  }

  /**
   * Return true if the listener of the lazy-loading event bus has been added.
   * @returns {boolean}
   */
  static isLazyLoadingListenerAdded() {
    return this.lazyLoadingListener !== undefined;
  }

  /**
   * The event handler for the lazy-loading event bus.
   * @param event containing the fragmentId and the htmlElements of the fragment that has been loaded.
   */
  static handleFragmentLoadedEvent(event) {
    const {
      fragmentId,
      htmlElements
    } = event.detail;
    SKIP_DEBUG || console.debug('Received fragmentLoaded event', fragmentId, htmlElements, this.lazyLoadableFragmentIds, this.selector);
    if (!this.recommendationFragmentIdRegExp.test(fragmentId)) {
      return;
    }
    const htmlSections = [...htmlElements].filter(htmlElement => htmlElement.matches(this.selector));
    if (htmlSections.length === 0) {
      SKIP_DEBUG || console.debug('No section matching', this.selector, 'found in the fragment', fragmentId, htmlElements);
      return;
    }
    for (const htmlSection of htmlSections) {
      SKIP_DEBUG || console.debug('Lazy-loading section', htmlSection.id, 'for fragment', fragmentId);
      if (!this.sectionIsInitialized(htmlSection)) {
        new this(htmlSection).safeInit();
      }
    }
  }

  /**
   * Add the breadcrumb path to the "data-merch-arbo" attribute of any child element of the section, if exists.
   */
  setDataMerchArbo() {
    const arbo = ['page_cat1_label', 'page_cat2_label', 'page_cat3_label', 'page_cat4_label'].filter(key => window.tc_vars[key] && window.tc_vars[key] !== '').map(key => window.tc_vars[key]);
    const childrenWithAttribute = this.section.querySelector('[data-merch-arbo]');
    if (childrenWithAttribute) {
      childrenWithAttribute.setAttribute('data-merch-arbo', arbo.join('/'));
    } else {
      console.warn('No element with attribute "data-merch-arbo" found in the section.');
    }
  }

  /**
   * Initialize the product sliders
   */
  initProductSlider() {
    this.section.querySelectorAll('.js-recommendation-swiper').forEach(slider => {
      // eslint-disable-next-line no-new
      new SwiperSlider(slider);
    });
  }

  /**
   * Add the event listener to the add to cart form of the item tiles.
   */
  handleItemTileAddToCartForm() {
    this.section.querySelectorAll('.js-cross-sell-add-to-cart-form').forEach(form => {
      form.addEventListener('submit', event => {
        event.preventDefault();
        const data = getFormData(form);
        data.reflmAvailability = null;
        this.cartUpdate({
          listProducts: [data]
        });
      });
    });
  }
}
_defineProperty(RecommendationComponent, "recommendationFragmentIdRegExp", /com\.adeo\.website\.productrecommendation\..*/);
/**
 * If true, the component will listen the lazy-loading event bus to initialize the sections that are lazy-loaded.
 */
_defineProperty(RecommendationComponent, "lazyLoadable", false);